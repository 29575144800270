import { useState, useEffect } from 'react';
import { getEdgeDevices, setDeviceInstructions, deleteDevice } from '../services/api';
import { Copy, Power, Trash2, RotateCcw, RefreshCw, ShieldCheck } from 'lucide-react';
import EdgeDeviceGenerateLicence from '../components/EdgeDeviceGenerateLicence';

const AdminEdgeDevicesPage = () => {
  const [devices, setDevices] = useState([]);
  const [loading, setLoading] = useState(false);
  const [vpnToggling, setVpnToggling] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [showLicenceModal, setShowLicenceModal] = useState(false);
  const [selectedSiteKey, setSelectedSiteKey] = useState(null);

  useEffect(() => {
    fetchDevices();
    // Set up polling interval only when modal is closed
    let interval;
    if (!showLicenceModal) {
      interval = setInterval(fetchDevices, 60000);
    }
    // Clean up interval on unmount or when modal opens
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [showLicenceModal]);

  const fetchDevices = async () => {
    try {
      // Don't show loading state on refresh to avoid flickering
      if (!devices.length) {
        setLoading(true);
      }
      const devicesData = await getEdgeDevices();
      // Sort devices so VPN enabled ones are at top
      const sortedDevices = devicesData.sort((a, b) => (b.status?.vpn || 0) - (a.status?.vpn || 0));
      setDevices(sortedDevices);
    } catch (error) {
      console.error('Error fetching devices:', error);
    } finally {
      setLoading(false);
    }
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
  };

  const handleVpnToggle = async (hwKey, currentStatus) => {
    try {
      setVpnToggling(true);
      await setDeviceInstructions(hwKey, { vpn: currentStatus ? 0 : 1 });
      
      // Add animation delay before refreshing
      setTimeout(async () => {
        await fetchDevices();
        setVpnToggling(false);
      }, 8000);
    } catch (error) {
      console.error('Error toggling VPN:', error);
      setVpnToggling(false);
    }
  };

  const handleServiceRestart = async (hwKey, service) => {
    if (window.confirm(`Are you sure you want to restart the ${service} service?`)) {
      try {
        await setDeviceInstructions(hwKey, { "restart-service": service });
        await fetchDevices();
      } catch (error) {
        console.error(`Error restarting ${service}:`, error);
      }
    }
  };

  const handleDeviceRestart = async (hwKey) => {
    if (window.confirm('Are you sure you want to restart this device?')) {
      try {
        await setDeviceInstructions(hwKey, { reboot: true });
        await fetchDevices();
      } catch (error) {
        console.error('Error restarting device:', error);
      }
    }
  };

  const handleDelete = async (hwKey) => {
    if (window.confirm('Are you sure you want to delete this device?')) {
      try {
        await deleteDevice(hwKey);
        setDevices(devices.filter(device => device.hw_key !== hwKey));
      } catch (error) {
        console.error('Error deleting device:', error);
      }
    }
  };

  const formatTimeAgo = (timestamp) => {
    const now = new Date();
    const lastSeen = new Date(timestamp + 'Z'); // Append Z to parse as UTC
    const diffSeconds = Math.floor((now - lastSeen) / 1000);

    if (diffSeconds < 60) return `${diffSeconds}s ago`;
    if (diffSeconds < 3600) return `${Math.floor(diffSeconds / 60)}m ago`;
    if (diffSeconds < 86400) return `${Math.floor(diffSeconds / 3600)}h ago`;
    return `${Math.floor(diffSeconds / 86400)}d ago`;
  };

  const formatExactTime = (timestamp) => {
    return new Date(timestamp).toLocaleString();
  };

  const hasActiveVpn = devices.some(device => device.status?.vpn === 1);

  const filteredDevices = devices.filter(device => 
    device.site_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    device.site_key.toLowerCase().includes(searchTerm.toLowerCase())
  );

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-gradient-to-br from-gray-50 to-white">
        <div className="relative">
          <div className="animate-spin rounded-full h-12 w-12 sm:h-16 sm:w-16 border-4 border-[#233563] border-t-transparent"></div>
          <div className="absolute inset-0 flex items-center justify-center">
            <div className="h-6 w-6 sm:h-8 sm:w-8 bg-gradient-to-r from-[#233563] to-blue-500 rounded-full animate-pulse"></div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-50 to-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-6 sm:py-12">
        <div className="relative mb-8 sm:mb-12">
          <h1 className="text-3xl sm:text-4xl font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-[#233563] to-blue-500">
            Edge Devices
          </h1>
          <div className="absolute -bottom-4 left-0 h-1 w-24 sm:w-32 bg-gradient-to-r from-[#233563] to-blue-500 rounded-full"></div>
        </div>

        <div className="mb-6">
          <input
            type="text"
            placeholder="Search by site name or key..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-full px-4 py-2.5 border border-gray-300 rounded-xl focus:outline-none focus:ring-2 focus:ring-[#233563]"
          />
        </div>

        <div className="bg-white rounded-2xl shadow-lg overflow-hidden">
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead>
                <tr>
                  <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Site Name
                  </th>
                  <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Site Key
                  </th>
                  <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Last Seen
                  </th>
                  <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    VPN Status
                  </th>
                  <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Backend API
                  </th>
                  <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Edge API
                  </th>
                  <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {filteredDevices.map((device) => (
                  <tr key={device.site_key} className="hover:bg-gray-50 transition-colors">
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                      {device.site_name}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      <div className="flex items-center space-x-2">
                        <span>{device.site_key}</span>
                        <button
                          onClick={() => copyToClipboard(device.site_key)}
                          className="p-1 hover:bg-gray-100 rounded-lg transition-colors text-gray-400 hover:text-gray-600"
                        >
                          <Copy size={16} />
                        </button>
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      <span title={formatExactTime(device.last_heartbeat)}>
                        {formatTimeAgo(device.last_heartbeat)}
                      </span>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <button
                        onClick={() => handleVpnToggle(device.hw_key, device.status?.vpn)}
                        disabled={vpnToggling || (hasActiveVpn && !device.status?.vpn)}
                        className={`inline-flex items-center px-4 py-2 rounded-xl text-sm font-medium transition-all duration-300 ${
                          device.status?.vpn
                            ? 'bg-gradient-to-r from-green-500 to-green-600 text-white hover:from-green-600 hover:to-green-700'
                            : 'bg-gradient-to-r from-gray-100 to-gray-200 text-gray-700 hover:from-gray-200 hover:to-gray-300'
                        } ${
                          (vpnToggling || (hasActiveVpn && !device.status?.vpn))
                            ? 'opacity-50 cursor-not-allowed'
                            : 'transform hover:scale-105 shadow-md hover:shadow-lg'
                        }`}
                      >
                        <Power
                          size={16}
                          className={`mr-2 ${vpnToggling ? 'animate-pulse' : ''}`}
                        />
                        {device.status?.vpn ? 'Connected' : 'Disconnected'}
                      </button>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="flex items-center space-x-2">
                        <span className={`${device.status?.backend_api ? 'text-green-600' : 'text-red-600'}`}>
                          {device.status?.backend_api ? 'Running' : 'Stopped'}
                        </span>
                        <button
                          onClick={() => handleServiceRestart(device.hw_key, 'backend-api')}
                          className="p-1 hover:bg-gray-100 rounded-lg transition-colors text-gray-400 hover:text-gray-600"
                        >
                          <RefreshCw size={16} />
                        </button>
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="flex items-center space-x-2">
                        <span className={`${device.status?.edge_api ? 'text-green-600' : 'text-red-600'}`}>
                          {device.status?.edge_api ? 'Running' : 'Stopped'}
                        </span>
                        <button
                          onClick={() => handleServiceRestart(device.hw_key, 'edge-api')}
                          className="p-1 hover:bg-gray-100 rounded-lg transition-colors text-gray-400 hover:text-gray-600"
                        >
                          <RefreshCw size={16} />
                        </button>
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap space-x-2">
                      <button
                        onClick={() => {
                          setSelectedSiteKey(device.site_key);
                          setShowLicenceModal(true);
                        }}
                        disabled={device.site_key === "Unknown"}
                        className={`inline-flex items-center px-4 py-2 rounded-xl text-sm font-medium text-white
                          bg-gradient-to-r from-[#233563] to-blue-500 hover:from-[#233563] hover:to-blue-600
                          transition-all duration-300 transform hover:scale-105 shadow-md hover:shadow-lg
                          ${device.site_key === "Unknown" ? 'opacity-50 cursor-not-allowed' : ''}`}
                      >
                        <ShieldCheck size={16} /> 
                      </button>
                      <button
                        onClick={() => handleDeviceRestart(device.hw_key)}
                        className="inline-flex items-center px-4 py-2 rounded-xl text-sm font-medium text-white
                          bg-gradient-to-r from-yellow-500 to-yellow-600 hover:from-yellow-600 hover:to-yellow-700
                          transition-all duration-300 transform hover:scale-105 shadow-md hover:shadow-lg mx-1"
                        title="Restart Device"
                      > 
                        <RotateCcw size={16} />
                      </button>
                      <button
                        onClick={() => handleDelete(device.hw_key)}
                        className="inline-flex items-center px-4 py-2 rounded-xl text-sm font-medium text-white
                          bg-gradient-to-r from-red-500 to-red-600 hover:from-red-600 hover:to-red-700
                          transition-all duration-300 transform hover:scale-105 shadow-md hover:shadow-lg"
                      >
                        <Trash2 size={16} />
                        
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {/* Licence Modal */}
      {showLicenceModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
          <div className="bg-white rounded-2xl shadow-xl max-w-2xl w-full max-h-[90vh] overflow-y-auto">
            <div className="p-6">
              <div className="flex justify-between items-center mb-6">
                <h2 className="text-2xl font-bold text-gray-900">Generate Licence</h2>
                <button
                  onClick={() => setShowLicenceModal(false)}
                  className="text-gray-500 hover:text-gray-700"
                >
                  ×
                </button>
              </div>
              <EdgeDeviceGenerateLicence siteKey={selectedSiteKey} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AdminEdgeDevicesPage;
